<template>
    <div id="ReasonTreeId" :style="`height:${containerHeight}px; width: 100%; border: 1px solid #eee  ; transform: scale(${zoom})`">

        <zm-tree-org ref="reasonTree" @container-height-change="changeHeight" :data="data" :toolBar="false"
            :disabled="false" :horizontal="false" :collapsable="false" :label-style="style" :node-draggable="true"
            :only-one-node="true" :clone-node-drag="false" :node-draging="nodeDragMove" :node-drag-end="nodeDragEnd"
            @on-expand="onExpand" @on-node-click="change" @on-node-copy="onNodeCopy" @on-logic-change="change"
            @on-node-added="change" @on-node-blur="change">
            <template slot-scope="{ node }">
                <v-hover v-slot:default="{ hover }">
                    <!-- :color="node.selected ? 'red' : node.resolved ? 'green' : ' lighten-2'" -->
                    <v-card :style="node.selected ? 'color:red;' : node.resolved ? 'color:green;' : '' "
                        :elevation="hover ? 10 : 1" :class="{ 'on-hover': hover }" class="mx-auto scope_content"
                        width="150" style="font-weight: bold;font-size: 20px;border: 1.5px solid #747474;margin: 0px 35px;">
                        <strong style="font-size: 10px">{{ getLevelIndex(node) }}</strong><br />
                        <v-row align="center" justify="center" style="margin: 0px 10px 10px;">{{ node.label }}</v-row>

                        <span class="arrow" v-if='node.children && node.children.length>0'></span>
                    </v-card>
                </v-hover>
            </template>
        </zm-tree-org>
    </div>
</template>
<script>
import html2canvas from 'html2canvas'

export default {
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            zoom: 1,
            depth: 0,
            style: {
                background: '#fff',
                color: '#5e6d82',
            },
            treeImage: '',
            containerHeight: 500,
            nodeId: ''
        }
    },
    model: {
        prop: 'data',
        event: 'change',
    },
    computed: {
        locked() {
            return this.$store.state.locked
        },
    },
    mounted() {
        // console.log('data', this.data)
        this.toggleExpand(this.data, this.expandAll)
        // this.toImage()
        this.$refs.reasonTree.refreshResolve()
        // 添加 Ctrl + 滚轮事件监听器
        window.addEventListener('wheel', this.handleWheel, { passive: false })
    },
    beforeDestroy() {
        // 移除事件监听器，防止内存泄漏
        window.removeEventListener('wheel', this.handleWheel)
    },
    methods: {
        handleWheel(event) {
            if (event.ctrlKey) {
                event.preventDefault()
                const delta = Math.sign(event.deltaY)
                const zoomStep = 0.1
                if (delta < 0) {
                    // 向上滚动，放大
                    this.zoom = Math.min(this.zoom + zoomStep, 10)
                } else {
                    // 向下滚动，缩小
                    this.zoom = Math.max(this.zoom - zoomStep, 0.1)
                }
            }
        },
        added(e) {
            console.log(e)
        },
        deleteed(e, data) {
            // console.log(data)
            // console.log(e)
            const ndata = data.children.filter(it => it.id != e.id)
            if (ndata == data.children) {
                ndata.forEach((itme) => {
                    this.deleteed(e, itme);
                })

            } else {
                data.children = ndata
            }

        },
        mouseover(e) {
            //e.preventDefault()
            //console.log(e)
            // this.nodeId = e.id
        },
        mouseout(e) {

        },
        changeHeight(height) {
            this.containerHeight = height
        },
        toImage() {
            html2canvas(this.$refs.reasonTree.$el, {}).then((canvas) => {
                this.treeImage = canvas.toDataURL()
            })
        },
        getDeep(data, i, deepArr) {
            //获取当前结点的子数组，并且打印当前结点的值
            var treeRoot = data.children
            //如果当前结点没有子数组了（没有子结点）就跳出当前递归,并且使计数器+1，并把计数器i的值存入深度数组中
            if (!treeRoot) {
                i++
                deepArr.push(i)
                return
            }
            //如果当前结点有子数组，就要使计数器+1
            i++
            //通过for循环来找出每一条路径，对遍历到的结点使用递归
            for (let j = 0; j < treeRoot.length; j++) {
                this.getDeep(treeRoot[j], i, deepArr) //递归时传入的就是当前结点的第j个子结点，当这第j个子结点中的所有子孙结点全部遍历完成之后，再去遍历第j+1个结点的所有子孙结点
            }
        },
        onExpand(e, data) {},
        onLogicChange(e, data) {},
        nodeDragMove(data) {},
        nodeDragEnd(data, isSelf) {},
        onNodeClick(e, data) {
            console.log('onNodeClick', e)
        },
        change() {
            this.$emit('change', this.data)
        },
        onNodeCopy() {},
        handleNodeAdd(node) {
            console.log('handleNodeAdd', node)
        },
        expandChange() {
            this.toggleExpand('expandChange', this.data, this.expandAll)
        },
        toggleExpand(data, val) {
            if (Array.isArray(data)) {
                data.forEach((item) => {
                    this.$set(item, 'expand', val)
                    if (item.children) {
                        this.toggleExpand(item.children, val)
                    }
                })
            } else {
                this.$set(data, 'expand', val)
                if (data.children) {
                    this.toggleExpand(data.children, val)
                }
            }
        },
        getLevelIndex(node) {
            if (node.root) return '0'
            const result = []
            this.getLabel(this.data.children, node, result)
            const label = result.join('.')
            node.prefix = label
            return label
        },
        getLabel(children, node, result) {
            for (let i = 0; i < children.length; i++) {
                result.push(`${i + 1}`)
                const child = children[i]
                if (child.id === node.id) {
                    return true
                }
                if (child.children) {
                    const ok = this.getLabel(child.children, node, result)
                    if (ok) return ok
                }
            }
            result.pop()
            return false
        },
    },
}
</script>    
<template>
	<table style="text-align: center;">
		<tr style="height: 80px">
			<th>序号</th>
			<th>功能载体</th>
			<th>功能</th>
			<th>功能对象</th>
			<th>功能性质</th>
			<th>功能等级</th>
			<th>执行程度</th>
			<th>得分</th>
			<th>总分</th>
			<th>操作</th>
		</tr>
		<template v-for="(node, i) in nodes">
			<tr style="text-align: center;height: 50px;" :key="i">
				<td colspan="9">
					<h4 style="color: #3f81c1;">
						&lt;组件：{{node && node!=undefined ? node.text : ""}}&gt;
					</h4>
				</td>
				<td>
					<v-btn
						v-show=" relations.filter((ele) => ele.from == node.id).some(ele => !ele.isExpanded && ele.isHide)"
						dense class="ma-2" style="margin-left: 20px; border-radius: 4px" tile outlined color="#3f81c1"
						@click="expandHideRelation(node.id)">
						<v-icon left>mdi-arrow-expand-vertical</v-icon>展开
					</v-btn>
					<v-btn
						v-show=" relations.filter((ele) => ele.from == node.id).some(ele => ele.isExpanded && ele.isHide)"
						dense class="ma-2" style="margin-left: 20px; border-radius: 4px" tile outlined color="#3f81c1"
						@click="expandHideRelation(node.id)">
						<v-icon left>mdi-arrow-collapse-vertical</v-icon>收起
					</v-btn>
				</td>
			</tr>

			<template
				v-for="(relation, j) in relations.filter((ele) => ele.from == node.id).filter(ele => ele.isExpanded || !ele.isHide)">
				<tr v-if="relation.isExpanded || !relation.isHide" :key="`${i}-${j}`">
					<td class="Triz_textarea">
						{{ j + 1 }}
					</td>
					<td v-if="j==0" class="Triz_textarea" :rowspan="computeRowspan(node)">
						{{ nodes.find(node => node.id == relation.from)?     nodes.find(node => node.id == relation.from).text : "" }}
					</td>
					<td class="Triz_textarea">
						<v-textarea rows="1" placeholder="【请输入】" @input="relationChange"
							v-model="relation.text"></v-textarea>
					</td>
					<td class="Triz_textarea">
						{{ nodes.find(node => node.id == relation.to) ? nodes.find(node => node.id == relation.to).text : ""  }}
					</td>
					<td class="Triz_Select-table">
						<v-select v-model="relation.performance" :items="qualityTypes" solo flat
							@change="changePerformance(relation)" density="compact" placeholder="【请选择】"></v-select>
					</td>
					<td class="Triz_Select-table">
						<v-select v-if="relation.performance != '有害'" v-model="relation.level" :items="functionScores" :disabled="isDisabled"
							solo flat @change="changeScore(relation)" density="compact" placeholder="【请选择】"></v-select>
					</td>
					<td class="Triz_Select-table">
						<v-select v-if="relation.performance != '有害'" v-model="relation.lineShape" 
							:items="excutionTypes" solo flat @change="relationChange(relation)" density="compact"
							placeholder="【请选择】"></v-select>
					</td>
					<td>
						{{ relation.score }}
					</td>
					<td v-if="j === 0" :rowspan="computeRowspan(node)" style="text-align: center">
						{{relations.filter((ele) => ele.from == node.id).filter(ele => ele.isExpanded || !ele
							.isHide).map((ele) => ele.score || 0).reduce((a, b) => a + b, 0)}}
					</td>
					<td>
						<v-btn v-if="!relation.isHide" dense class="ma-2" tile outlined color="#3f81c1"
							@click="removeRalation(relation)" style=" border-radius: 6px">
							<v-icon left>mdi-receipt-text-minus-outline</v-icon> 删除
						</v-btn>
						<v-btn v-if="relation.isHide" dense class="ma-2" tile outlined color="#3f81c1"
							@click="recoverRalation(relation)" style=" border-radius: 6px">
							<v-icon left>mdi-receipt-text-plus</v-icon> 恢复
						</v-btn>
					</td>
				</tr>
			</template>
		</template>
	</table>
</template>
<script>
	import {
		functionScores,
		qualityTypes,
		excutionTypes,
		lineShapes
	} from '@/store/default'

	export default {
		props: {
			nodes: {
				type: Array,
				default: () => [],
			},
			relations: {
				type: Array,
				default: () => [],
			},
			functions: {
				type: Array,
				default: () => [],
			},
		},
		model: {
			prop: 'functions',
			event: 'change',
		},
		data() {
			return {
				functionScores,
				qualityTypes,
				excutionTypes,
				lineShapes,
				isDisabled: true,
			}
		},
		computed: {
			// 占位空格内容
			all() {
				return []
			},
		},
		mounted() {
			console.log(this.nodes)
			console.log(this.relations)
		},
		methods: {
			expandHideRelation(nodeId) {
				this.relations.forEach((relation,index) => {
					if (relation.from == nodeId) {
						relation.isExpanded = !relation.isExpanded
						this.$set(this.relations, index, relation);
					}
				})
				this.$emit('relation', this.relations)
			},
			removeRalation(relation) {
				this.relations.forEach((rel,index) => {
					if (rel.from == relation.from && rel.to== relation.to) {
						rel.isHide = true
						rel.score = 0
						this.$set(this.relations, index, rel);
					}
				})
				
				this.$emit('relation', this.relations)
			},
			recoverRalation(relation) {
				this.relations.forEach((rel,index) => {
					if (rel.from == relation.from && rel.to== relation.to) {
						rel.isHide = false
						rel.score = rel.level?functionScores.find((ele) => ele.value == rel.level).score:0
						this.$set(this.relations, index, rel);
					}
				})
				
				this.$emit('relation', this.relations)
			},
			change() {
				this.$emit('change')
			},
			relationChange(relation) {
				if (relation.lineShape == this.lineShapes.bad || relation.lineShape == this.lineShapes.unsatisfy) {
					relation.problem = ''
				} else {
					delete relation.problem
				}
				this.$emit('relation', this.relations)
			},
			computeRowspan(node) {
				const rowspan = this.relations.filter((ele) => ele.from == node.id).filter(ele => ele.isExpanded || !ele
					.isHide)
				return rowspan.length
			},
			changePerformance(relation) {
				console.log('changePerformance',relation)
				if (relation.performance == '有害') {
					relation.lineShape = lineShapes.bad
					relation.rule = '有害功能'
					relation.level = ''
					relation.score = 0
				} else {
					const data=this.nodes.find(node => node.id == relation.to).data
					if(data.isNo1){
						relation.level = '基本功能'
						relation.score = 3
					}else if(data.super){
						relation.level = '附加功能'
						relation.score = 2
					}else{
						relation.level = '辅助功能'
						relation.score = 1
					}
					relation.lineShape = undefined
					relation.rule = undefined
				}
				this.relationChange(relation)
			},
			changeScore(relation) {
				relation.score = functionScores.find((ele) => ele.value == relation.level).score
				this.relationChange(relation)
			},
		},
	}
</script>
<style scoped></style>